<template>
  <div class="content">
    <p class="title">{{ $t("mechat").title }}</p>
    <div v-if="texts" class="text" v-for="(item, index) in texts" :key="index">
      <p v-if="item.header" class="innerTitle">
        {{ item.content }}
      </p>
      <p v-else>
        {{ item }}
      </p>
    </div>
  </div>
</template>
<script>
import { useI18n } from "vue-i18n";
export default {
  data() {
    return {
      lang: 1,
      locale: "zh-CN",
    };
  },
  computed: {
    mechat() {
      let mechat = {
        title: "",
        text: [],
      };
      const data = this.$t("mechat");
      for (let item in data) {
        if (item == "title") {
          mechat.title = data[item];
        } else if (item.indexOf("text") != -1) {
          mechat.text.push(data[item]);
        } else if (item.indexOf("header") != -1) {
          mechat.text.push({
            header: true,
            content: data[item],
          });
        }
      }
      return mechat;
    },
    texts() {
      return this.mechat.text;
    },
    langs() {
      const messages = this.$i18n.messages;
      let langs = [];
      for (const langName in messages) {
        const curmsg = messages[langName];
        let name = messages[this.locale].langs[langName];
        let item = {
          text: `${name}(${curmsg.langName})`,
          fulltext: [name, curmsg.langName],
          value: langName,
          code: curmsg.code,
          encode: curmsg.encode,
        };
        langs.push(item);
      }
      return langs;
    },
  },
  mounted() {
    if (this.$route.query.lang) {
      this.lang = Number(this.$route.query.lang);
    }
    const locales = this.langs.filter((lang) => lang.code == this.lang);
    if (locales.length != 0) {
      const locale = locales[0].value;
      const catlange = localStorage.getItem("locale");
      if (locale != catlange) {
        localStorage.setItem("langCode", this.lang);
        localStorage.setItem("locale", locale);
        try {
          this.$i18n.locale = locale;
        } catch (error) {
          console.log(error);
        }
      }
    }
  },
};
</script>
<style lang="stylus" scoped>
.content
  padding: 15px;
  background: transparent;
  p
    padding 0;
  .title
    font-size: 16px;
    font-weight: 700;
    margin: 20px 0;
    text-align: center;
  .text
    font-size: 12px;
    margin: 10px 0;
    .innerTitle
      font-size: 14px;
      font-weight: 600;
      margin: 15px 0;
</style>
