var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("p", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("mechat").title))
      ]),
      _vm._l(_vm.texts, function(item, index) {
        return _vm.texts
          ? _c("div", { key: index, staticClass: "text" }, [
              item.header
                ? _c("p", { staticClass: "innerTitle" }, [
                    _vm._v("\n      " + _vm._s(item.content) + "\n    ")
                  ])
                : _c("p", [_vm._v("\n      " + _vm._s(item) + "\n    ")])
            ])
          : _vm._e()
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }